<template>
  <div></div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["getMagazine", "getDocument"]),
  },
  async mounted() {
    try {
      let fileUrl;
      if (this.$route.name === "magazine") {
        let magazineId = this.$route.params.id;
        let magazine = await this.getMagazine(magazineId);
        fileUrl = magazine.url;
      }
      if (this.$route.name === "reading") {
        let { doc } = this.$route.params;
        doc = doc.toLowerCase().replace(/-/gi, "_");
        if (doc === "spartan_business_guide_v1") doc = "business_guide";
        let document = await this.getDocument(doc);
        fileUrl = document.url;
      }
      window.location = fileUrl;
    } catch (error) {
      console.log(error);
      this.$router.push({ name: "home" });
    }
  },
};
</script>
<style lang="scss" scoped>
</style>